.CorporationEditInput {
  text-align: center;
  border-radius: 8px !important;
  border-left: 0px !important;
  background: #F5F7F9 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  text-indent: -15px;
}

.CorporationEditInputIconSpan {
  background: #F5F7F9 !important;
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}

.CorporationEdit .DateInput_input{
    height: 38px;
    text-align: center;
    font-size: 1rem;
    color: #495057;
    padding: .375rem 1.75rem .375rem .75rem;
}

.CorporationEdit .DateInput_input::placeholder {
    color: #495057;
}

.CorporationEdit .DateInput_input:disabled::placeholder {
    color: #6c757d;
}

.CorporationEdit .DateInput_input__disabled_4 {
    font-style: normal;
    background-color: #F7F9FA;
}
.CorporationEdit .SingleDatePickerInput {
    border-left: 0px;
}

.CorporationEdit .DivNameInput {
  font-weight: 400;
  background: #F0F0F0;
}

.CorporationEdit .DivNameInput:disabled {
  background: #00000000;
  border: 0px;
}
