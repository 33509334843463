/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

select:focus, select:active, select.active {
  outline: none !important;
  box-shadow: none !important;
  border-color:  #ced4da !important;
}

input:focus, input:active, input.active {
  outline: none !important;
  box-shadow: none !important;
  border-color:  #ced4da !important;
}

.Header{
    background-color:ghostwhite;
}
.Header-Container{
    height: 60px;
    padding: 0.5em;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.HeaderIconContainer{
    display: inline;
}
.HeaderRightIcon{
    margin-left: .5em;
}
.Header-Bottom {
    background: linear-gradient(to right, #00b4db, #0083b0); 
    height: 60px;
    padding: 0.5em 0;
}
.Header-Bottom-Icon-Container{
    position: relative;
    background-color: #fff;
    bottom:-50%;
}
.Header-Bottom-Icon-Item{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}
.NavBar-Brand {
    height: 100%;
}
.NavLink {
    color: #3f3f3f;
    padding: 1em;
    border-right: 1px solid #ccc;
}
.NavLink:hover {
    color: #0083b0;
    font-weight: bold;
    text-decoration: none;
}
.Header-Bottom-Icon-Item.Active {
    color: #0083b0;
    font-weight: bold;

}

.Header-Bottom-Icon-Item.Active::after{
    content: "^";
    -webkit-transform: rotate(180deg) scaleX(2);
            transform: rotate(180deg) scaleX(2);
    position: absolute;
    bottom: 0;
    margin: 0;
}
.Navlink-Tag{
    font-size: .7em;
    margin-top: .5em;
}

.Language {
    cursor:pointer;
    color: #333;
    text-align: center;
    
}

.Language-Active {
    font-weight: bold;
}
.Content {
    width: 90%;
    margin: auto;
}
.Content-Layout {
    margin-top: 5em;
    min-height: calc(calc(100vh - 190px) - 4em);
}
.Footer {
    height: 70px;
    background-color:#eee;
    color:#3f3f3f;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -1px 0 4px rgba(0,0,0,.4);
}
.Progress {
    position:relative;
    top: calc(50% - 40px);
    left:calc(50% - 40px);
}
@media screen and (max-width:600px){
    .Content{
        width:100%;
    }
}

.ButtonGroupItem{
    background-color: #39708C!important;
    border-color: #39708C!important;
}
.ButtonGroupItem:focus{
    outline: none!important;
    box-shadow: none!important;
}
.ButtonGroupItem:active{
    outline: none!important;
    box-shadow: none!important;
}
.ButtonGroupItem.active{
    outline: none!important;
    box-shadow: none!important;
}
.ButtonGroup .dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;
}
.gradient-summary-card-container{
    display: flex;
    position: relative;
    min-height: 130px;
    background: linear-gradient(to right, #00b4db, #0083b0);
    padding:.5em;
    color: white;
    flex-direction: row;
    justify-content: space-between;
}
.gradient-summary-card-container.Small{
    padding: .3em;
}
.gradient-summary-card-container .left{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.gradient-summary-card-container .left .title{
    text-transform: uppercase;
    font-size:1em;
}
.gradient-summary-card-container.Small .left .title{
    font-size:.9em;
}
.gradient-summary-card-container .left .value{
    font-size: 1.7em;
    font-weight: bold;
}
.gradient-summary-card-container.Small .left .value{
    font-size: 1.2em;
}
.gradient-summary-card-container .left .description{
    font-size:.7em;
}

.gradient-summary-card-container .right{
    display: flex;
    align-items: center;             

}
.gradient-summary-card-container .left,.gradient-summary-card-container .right {
    z-index: 10;
}
.Gradient-Icon {
    background-color: rgba(255,255,255, .2);
    border-radius: 50%;
    padding:.3em;
    margin-right: .3em;
    -webkit-transform:scale(1.3);
            transform:scale(1.3);
}
.gradient-summary-card-container.Small .right .icon {
    background-color: rgba(255,255,255, .5);
    width: 1em;
    height:1em;
}
.VaweSvg{
    position: absolute;
    top: 0;
    left: 0;

}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

input {
  border-radius: 5px;
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  
  input {
    border-radius: 5px;
  }
.ProfileCard {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ProfileCard-GoBack {
    background-color: #f3f3f3;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    top: 15px;
    left: 15px;
}

.ProfileCard-Image-Container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ProfileCard-Image-Container:hover  .ProfileCard-Image{
    opacity: 0.2;
    filter: alpha(opacity=20);
}

.ProfileCard-Image-Container .AddImageLabel{
    color:"white";
    position: absolute;
    display: none;
    cursor: pointer;
}
.ProfileCard-Image-Container:hover .AddImageLabel{
    display: block;
}
.ProfileCard-Image {
    border-radius: 50%;
    background-color: #ccc;
    width: 150px;
    height: 150px;
}
.ProfileCard-Image.loading {
    opacity: 0.1;
    filter: alpha(opacity=10);
}

.ProfileCard .DateInput_input {
    height: 38px;
    text-align: center;
    font-size: 1rem;
    color: #495057;
    padding: .375rem 1.75rem .375rem .75rem;
}

.ProfileCard .DateInput_input::-webkit-input-placeholder {
    color: #495057;
}

.ProfileCard .DateInput_input::placeholder {
    color: #495057;
}

.ProfileCard .DateInput_input:disabled::-webkit-input-placeholder {
    color: #6c757d;
}

.ProfileCard .DateInput_input:disabled::placeholder {
    color: #6c757d;
}

.ProfileCard .DateInput_input__disabled_4 {
    font-style: normal;
    background-color: #e9ecef;
}

.ProfileCard .SingleDatePickerInput {
    border-left: 0px;
}

.ProfileCard .CorpList {
    background-color:#e9ecef;
    border: 1px solid #ced4da;
    width: 100%;
    color: #495057;
    margin-bottom: .5em;
    border-radius: 5px;
    padding:.5em;
    padding-left: .5em;
    display: flex;    
    align-items: center;
}

.ProfileCard .CorpList ul {
    margin-bottom: 0;
    list-style: none;
    flex-grow: 1;
    text-align: center;
    padding-left: 0;
}
.FamilyMemberListCell {
  padding: 0!important;
}
.Member-Name {
    font-weight: bold!important;
    font-size: .8em!important;
    padding-right: 2px!important;
}

.Member-Mail {
    font-size: .7em!important;
    color:#4f4f4f!important;
}
.FamilyMemberRemoveButton{
    visibility: hidden;
}
.FamilyMemberListRow:hover .FamilyMemberRemoveButton {
    visibility: visible;
}
.mail-add {
    cursor: pointer;
}

.mail-add-disabled{
    cursor: initial;
    color: #eee!important;
    background-color: #ddd!important;
}

.bubble {
	position: relative;
	background: #F0F0F0;
	border-radius: .4em;
  border-top-left-radius: 0;
}

.bubble:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-left: 0;
		margin-left: -12px;
    border-right: 15px solid #F0F0F0;
		border-bottom: 15px solid transparent;
  }

.dot:after {
    content: '';
    position: absolute;
		left: -34px;
    top: -6px;
    width: 15px;
    height: 15px;
		border-radius: 50%;
  }

.dot.register:after {
background-color: #23738C;
}

.dot.purchase:after {
	background-color: #00BC9D;
}

.dot.cancel:after {
	background-color: #FF6A7C;
}

.HideContentContainer {
  position: relative;
}

.HideContentContainer .Modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.527);
  -webkit-backdrop-filter: blur(20px) sepia(10%);
          backdrop-filter: blur(20px) sepia(10%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.PurchaseButton:hover span{
  color:white!important;
}

.PurchaseContainer li {
  color:#7B7B7B;
}
.PurchaseContainer{
  display: flex;
  justify-content: space-between;
  align-items: center!important;
}
.PurchaseButton:hover span{
  cursor: pointer;
  color:#7b7b7b!important;
}

@media only screen and (max-width: 1600px) {
  .ItemsAndCouponContainer{
    flex-direction: column!important;
  }
  .PurchaseContainer .Products {
    margin-top: 1em;
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto';
  color: #384a5e;
}

.onePageDiv {
  padding: 35px 50px 35px 50px;
  width: 595pt;
  height: 842pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: relative;
  page-break-inside: avoid;
  break-inside: avoid-page;
  page-break-after: always;
  -webkit-column-break-after: always;
          break-after: always;
}

.allPages{
  height: 3360pt;
}

.gricevizLogo {
width: 210px;
}

.brainImageBox{
text-align: right;
position: absolute;
top: 0;
right: 0;
width: 350px;
height: 200px;
z-index: 0;
}

.brainImageText{
position: absolute;
top: 40px;
right: 50px;
font-size: 22pt;
font-weight: 900;
z-index: 2;
}

.headerDate {
  color: #7c7c7c;
  font-size: 11pt;
  font-weight: 400;
  position: absolute;
  top: 80px;
  right: 50px;
}

.brainImage{
z-index: 0;
width: 100%;
}



.headerBigText {
  font-size: 22pt;
  font-weight: 900;
  margin-bottom: 20px;
}




.avatar {
  margin-top: 10px;
  margin-bottom: 7px;
  width: 35mm;
  height: 35mm;
  border-radius: 1000px;
}

.name {
  font-size: 30pt;
  font-weight: 900;
}
.birthDate{
  width: 120px;
  color: white;
  background-color: #384a5e;
  font-weight: 500;
  font-size: 11pt;
  padding: 3px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 35px;

}

.birthDateIcon{
  font-size: 12pt!important;
  margin-right: 4px;
  margin-bottom: 3px;
  
}

.subHeader {
  font-size: 22pt;
  font-weight: 900;
 
}
.categoryBox {
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
}

.categoryIcon {
  width: 42px;
  height: 42px;
}

.categoryName {
  font-size: 22pt;
  font-weight: bold;
  margin-left: 7px;
}

.featureName {
  font-size: 10pt;
  font-weight: 500;
  margin-top: 8px;
  line-height: 12pt;
  display: flex;
  align-items: center;
}

.subFeature {
  margin-left: 7px;
  font-size: 10pt;
  line-height: 12pt;
  font-weight: 300;
}

.categoryIconAndFeaturesRow {
  flex: 1 1;
}

.scoreProgressDiv {
  width: 500px;
  height: 250px;
  margin-left: 4px;
  border-radius: 20px;
  background-color: #f5f8f9;
  padding: 20px;
}

.radarBox{
  width: 100%;
  height: 480px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #f5f8f9;
  border-radius: 20px;
  padding: 40px;
  margin-top: 24px;
}

.gameLevelsBox{
  width: 100%;
  height: 400px;

  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.playCountsByCategory{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageFooter{
position: absolute;
bottom: 0;
width: 100%;
padding: 0px 40px 30px 40px;
font-size: 10pt;
}

.footerLeftItemRow{
  
}

.footerLeftItem{
margin-right: 15px;
font-weight: 400;
color: #7c7c7c;
}

.footerPageNo{
width: 90px;
color: #231f20;
}


.CorporationCard .DetailsIcon {
    cursor:pointer;
    position: relative;
}

.CorporationCard .DetailsIcon:hover .Details {
    visibility: visible
}

.CorporationCard .DetailsIcon .Details{
    visibility: hidden;
    position: absolute;
    left: -130px;
    bottom: -140px;
    width: 300px;
    min-height: 125px;
    border: 1px solid #000000;
    background-color: #ffffff;
    z-index: 99;
    
}
.CorporationCard .DetailsIcon .Details:after, .CorporationCard .DetailsIcon .Details:before{
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
    pointer-events: none;
}
.CorporationCard .DetailsIcon .Details:after{
    border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
	border-width: 8px;
	margin-left: -8px;
}
.CorporationCard .DetailsIcon .Details:before {
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: #000000;
	border-width: 10px;
	margin-left: -10px;
}
.AddCorporation .DateInput_input{
    height: 38px;
    text-align: center;
    font-size: 1rem;
    color: #495057;
    padding: .375rem 1.75rem .375rem .75rem;
}
.AddCorporation .DateInput_input::-webkit-input-placeholder {
    color: #495057;
}
.AddCorporation .DateInput_input::placeholder {
    color: #495057;
}
@media only screen and (max-width: 1428px) {
    .form-inline.RestrictionBarActive  {
      display:flex!important;
      justify-content: flex-end;
      margin-right: 0!important;
      margin-bottom: .5em;
      width: 100%;
      float: none;
    }
  }

  @media only screen and (max-width: 1000px) {
    .form-inline.RestrictionBarActive  {
      display:flex!important;
      justify-content: center;
      margin-right: 0!important;
      margin-bottom: .5em;
      width: 100%;
      float: none;
    }
  }
.Corporation .ImageContainer {
    position:relative;
    padding-bottom:100%;
    overflow:hidden;
}

.Corporation .Image {
    width: 50%;
    height:50%;
    background-color:white;
    position:absolute;
    left: 25%;
    top:15%;
}

.Corporation .ImageContainer .Name {
    position: absolute;
    top: 68%;
    width: 100%;
    text-align: center;
}
.Corporation .DivisionSelect{
    display: flex;
    justify-content: space-between;
    cursor: pointer;    
    background-color: #F6F7F9;
    padding: 5px 10px;
    margin: 7px 0;
    border-radius: 3px;
}
.Corporation .DivisionSelect.Active {
    background-color: #7A7A7A;
    color: white;
    cursor: default;
    transition: background-color .2s;
    
}
table.ReportSimpleList {
  border: 1px solid rgba(224, 224, 224, 1);
  width: 100%;
  text-align: center;
}

.SimpleListHeader{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5em;
}
.SimpleListHeader img {
  max-width: 70px;
  max-height: 70px;
  margin-right: 2em;
}
.SimpleListHeader h3 {
  color: #36495C;
  margin-left: .5em;
}
.OrderByCategoryList{
  width: 100%;
}
.OrderByCategoryList .CategoryName > div{
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  text-align: center;
}
.OrderByCategoryList > tr{
  page-break-inside: avoid!important;
}

.ReportContainer{
  padding-bottom: 9em;
}
.ReportContainer > button{
  margin-bottom: 2em;
}


.CorporationEditInput {
  text-align: center;
  border-radius: 8px !important;
  border-left: 0px !important;
  background: #F5F7F9 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  text-indent: -15px;
}

.CorporationEditInputIconSpan {
  background: #F5F7F9 !important;
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}

.CorporationEdit .DateInput_input{
    height: 38px;
    text-align: center;
    font-size: 1rem;
    color: #495057;
    padding: .375rem 1.75rem .375rem .75rem;
}

.CorporationEdit .DateInput_input::-webkit-input-placeholder {
    color: #495057;
}

.CorporationEdit .DateInput_input::placeholder {
    color: #495057;
}

.CorporationEdit .DateInput_input:disabled::-webkit-input-placeholder {
    color: #6c757d;
}

.CorporationEdit .DateInput_input:disabled::placeholder {
    color: #6c757d;
}

.CorporationEdit .DateInput_input__disabled_4 {
    font-style: normal;
    background-color: #F7F9FA;
}
.CorporationEdit .SingleDatePickerInput {
    border-left: 0px;
}

.CorporationEdit .DivNameInput {
  font-weight: 400;
  background: #F0F0F0;
}

.CorporationEdit .DivNameInput:disabled {
  background: #00000000;
  border: 0px;
}

.CompareUsers .DivisionSelect{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: #F6F7F9;
    padding: 5px 10px;
    margin: 7px 0;
    border-radius: 3px;
}

.CompareUsers .DivisionSelect.Active {
    background-color: #7A7A7A;
    color: white;
    cursor: default;
    transition: background-color .2s;
}
.CompareUsers .DivisionSelectContainer {
    height: 200px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

.CompareUsers .DivisionSelectContainer::-webkit-scrollbar { /* WebKit */
    width: 0px;
}
.nav-card {
  color: black;
  text-decoration: none;
}

.nav-card:hover {
  /* color: #007bff; */
  text-decoration: none;
}

.card-container:hover {
  background: #d2d3d4;
}

.card-container {
  border-bottom: #007bff;
  border: #007bff;
  border-bottom-width: 2px;
}

.nav-card-active-class {
  color: #007bff;
}

.Login{
    background:  #F5F7F9;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}

.LoginCard {
  position: relative;
  margin: auto;
  border: 5px;
  width: 70%;
  height: 35vw;
  min-width: 600px;
  min-height: 450px;
  max-width: 1400px;
  box-shadow: 0px 0px 36px 5px rgba(0,0,0,0.2);
  -webkit-animation: .8s linear loginFormTranslate forwards;
          animation: .8s linear loginFormTranslate forwards;
}

.LoginForm {
  position: relative;
}

.LoginInput {
  text-align: center;
  border-radius: 8px !important;
  border-left: 0px !important;
  background: #F5F7F9 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  text-indent: -45px;
}

.LoginInputIconSpan {
  background: #F5F7F9 !important;
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}


.LoginRightSide {
  background-repeat: no-repeat;
  background-position: 20vw 30%;
  height: 100%;
  background-size: auto 90%;
  padding-left: 5%;
  overflow: hidden;
}

.LoginBrainquire {
  width: 13vw;
}

.FacebookButton {
  width: 100%;
  background: #365C91;
  border: 0px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  height: 35px;
}

@media screen and (max-width : 992px){
  .LoginCard{
    height: 80%;
  }
  .LoginBrainquire {
    width: 30vw;
  }
  .LoginRightSide {
    background-position: 110% 50%;
    height: 100%;
    background-size: auto 100%;
    padding-left: 10%;
  }
}
@-webkit-keyframes loginFormTranslate {
    0%{
        opacity:.1;
    }
    60%{
        opacity:.6;
    }
    100%{
        opacity:1;
    }
}
@keyframes loginFormTranslate {
    0%{
        opacity:.1;
    }
    60%{
        opacity:.6;
    }
    100%{
        opacity:1;
    }
}

.AboutPages {
    color: #4A4B4B;
    text-align: left;
    padding: 3em;
   
}
.AboutPages h4 {
    text-align: left;
    font-weight: 400;
    color: #00A0B4;
    font-size: 40px;
}
.AboutPages h3 {
    text-align: left;
    font-weight: 900;
    color: #00A0B4;
    font-size: 16px;

}

