.FamilyMemberListCell {
  padding: 0!important;
}
.Member-Name {
    font-weight: bold!important;
    font-size: .8em!important;
    padding-right: 2px!important;
}

.Member-Mail {
    font-size: .7em!important;
    color:#4f4f4f!important;
}
.FamilyMemberRemoveButton{
    visibility: hidden;
}
.FamilyMemberListRow:hover .FamilyMemberRemoveButton {
    visibility: visible;
}
.mail-add {
    cursor: pointer;
}

.mail-add-disabled{
    cursor: initial;
    color: #eee!important;
    background-color: #ddd!important;
}
