.CompareUsers .DivisionSelect{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: #F6F7F9;
    padding: 5px 10px;
    margin: 7px 0;
    border-radius: 3px;
}

.CompareUsers .DivisionSelect.Active {
    background-color: #7A7A7A;
    color: white;
    cursor: default;
    transition: background-color .2s;
}
.CompareUsers .DivisionSelectContainer {
    height: 200px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

.CompareUsers .DivisionSelectContainer::-webkit-scrollbar { /* WebKit */
    width: 0px;
}