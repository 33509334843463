.HideContentContainer {
  position: relative;
}

.HideContentContainer .Modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.527);
  backdrop-filter: blur(20px) sepia(10%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}