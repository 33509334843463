.CorporationCard .DetailsIcon {
    cursor:pointer;
    position: relative;
}

.CorporationCard .DetailsIcon:hover .Details {
    visibility: visible
}

.CorporationCard .DetailsIcon .Details{
    visibility: hidden;
    position: absolute;
    left: -130px;
    bottom: -140px;
    width: 300px;
    min-height: 125px;
    border: 1px solid #000000;
    background-color: #ffffff;
    z-index: 99;
    
}
.CorporationCard .DetailsIcon .Details:after, .CorporationCard .DetailsIcon .Details:before{
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
    pointer-events: none;
}
.CorporationCard .DetailsIcon .Details:after{
    border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
	border-width: 8px;
	margin-left: -8px;
}
.CorporationCard .DetailsIcon .Details:before {
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: #000000;
	border-width: 10px;
	margin-left: -10px;
}