.Login{
    background:  #F5F7F9;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}

.LoginCard {
  position: relative;
  margin: auto;
  border: 5px;
  width: 70%;
  height: 35vw;
  min-width: 600px;
  min-height: 450px;
  max-width: 1400px;
  -webkit-box-shadow: 0px 0px 36px 5px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 36px 5px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 36px 5px rgba(0,0,0,0.2);
  animation: .8s linear loginFormTranslate forwards;
}

.LoginForm {
  position: relative;
}

.LoginInput {
  text-align: center;
  border-radius: 8px !important;
  border-left: 0px !important;
  background: #F5F7F9 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  text-indent: -45px;
}

.LoginInputIconSpan {
  background: #F5F7F9 !important;
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}


.LoginRightSide {
  background-repeat: no-repeat;
  background-position: 20vw 30%;
  height: 100%;
  background-size: auto 90%;
  padding-left: 5%;
  overflow: hidden;
}

.LoginBrainquire {
  width: 13vw;
}

.FacebookButton {
  width: 100%;
  background: #365C91;
  border: 0px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  height: 35px;
}

@media screen and (max-width : 992px){
  .LoginCard{
    height: 80%;
  }
  .LoginBrainquire {
    width: 30vw;
  }
  .LoginRightSide {
    background-position: 110% 50%;
    height: 100%;
    background-size: auto 100%;
    padding-left: 10%;
  }
}
@keyframes loginFormTranslate {
    0%{
        opacity:.1;
    }
    60%{
        opacity:.6;
    }
    100%{
        opacity:1;
    }
}
