.Content {
    width: 90%;
    margin: auto;
}
.Content-Layout {
    margin-top: 5em;
    min-height: calc(calc(100vh - 190px) - 4em);
}
.Footer {
    height: 70px;
    background-color:#eee;
    color:#3f3f3f;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -1px 0 4px rgba(0,0,0,.4);
}
.Progress {
    position:relative;
    top: calc(50% - 40px);
    left:calc(50% - 40px);
}
@media screen and (max-width:600px){
    .Content{
        width:100%;
    }
}
