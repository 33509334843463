table.ReportSimpleList {
  border: 1px solid rgba(224, 224, 224, 1);
  width: 100%;
  text-align: center;
}

.SimpleListHeader{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5em;
}
.SimpleListHeader img {
  max-width: 70px;
  max-height: 70px;
  margin-right: 2em;
}
.SimpleListHeader h3 {
  color: #36495C;
  margin-left: .5em;
}
.OrderByCategoryList{
  width: 100%;
}
.OrderByCategoryList .CategoryName > div{
  transform: rotate(-90deg);
  text-align: center;
}
.OrderByCategoryList > tr{
  page-break-inside: avoid!important;
}

.ReportContainer{
  padding-bottom: 9em;
}
.ReportContainer > button{
  margin-bottom: 2em;
}

