.gradient-summary-card-container{
    display: flex;
    position: relative;
    min-height: 130px;
    background: linear-gradient(to right, #00b4db, #0083b0);
    padding:.5em;
    color: white;
    flex-direction: row;
    justify-content: space-between;
}
.gradient-summary-card-container.Small{
    padding: .3em;
}
.gradient-summary-card-container .left{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.gradient-summary-card-container .left .title{
    text-transform: uppercase;
    font-size:1em;
}
.gradient-summary-card-container.Small .left .title{
    font-size:.9em;
}
.gradient-summary-card-container .left .value{
    font-size: 1.7em;
    font-weight: bold;
}
.gradient-summary-card-container.Small .left .value{
    font-size: 1.2em;
}
.gradient-summary-card-container .left .description{
    font-size:.7em;
}

.gradient-summary-card-container .right{
    display: flex;
    align-items: center;             

}
.gradient-summary-card-container .left,.gradient-summary-card-container .right {
    z-index: 10;
}
.Gradient-Icon {
    background-color: rgba(255,255,255, .2);
    border-radius: 50%;
    padding:.3em;
    margin-right: .3em;
    transform:scale(1.3);
}
.gradient-summary-card-container.Small .right .icon {
    background-color: rgba(255,255,255, .5);
    width: 1em;
    height:1em;
}
.VaweSvg{
    position: absolute;
    top: 0;
    left: 0;

}