.ProfileCard {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ProfileCard-GoBack {
    background-color: #f3f3f3;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    top: 15px;
    left: 15px;
}

.ProfileCard-Image-Container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ProfileCard-Image-Container:hover  .ProfileCard-Image{
    opacity: 0.2;
    filter: alpha(opacity=20);
}

.ProfileCard-Image-Container .AddImageLabel{
    color:"white";
    position: absolute;
    display: none;
    cursor: pointer;
}
.ProfileCard-Image-Container:hover .AddImageLabel{
    display: block;
}
.ProfileCard-Image {
    border-radius: 50%;
    background-color: #ccc;
    width: 150px;
    height: 150px;
}
.ProfileCard-Image.loading {
    opacity: 0.1;
    filter: alpha(opacity=10);
}

.ProfileCard .DateInput_input {
    height: 38px;
    text-align: center;
    font-size: 1rem;
    color: #495057;
    padding: .375rem 1.75rem .375rem .75rem;
}

.ProfileCard .DateInput_input::placeholder {
    color: #495057;
}

.ProfileCard .DateInput_input:disabled::placeholder {
    color: #6c757d;
}

.ProfileCard .DateInput_input__disabled_4 {
    font-style: normal;
    background-color: #e9ecef;
}

.ProfileCard .SingleDatePickerInput {
    border-left: 0px;
}

.ProfileCard .CorpList {
    background-color:#e9ecef;
    border: 1px solid #ced4da;
    width: 100%;
    color: #495057;
    margin-bottom: .5em;
    border-radius: 5px;
    padding:.5em;
    padding-left: .5em;
    display: flex;    
    align-items: center;
}

.ProfileCard .CorpList ul {
    margin-bottom: 0;
    list-style: none;
    flex-grow: 1;
    text-align: center;
    padding-left: 0;
}