.AboutPages {
    color: #4A4B4B;
    text-align: left;
    padding: 3em;
   
}
.AboutPages h4 {
    text-align: left;
    font-weight: 400;
    color: #00A0B4;
    font-size: 40px;
}
.AboutPages h3 {
    text-align: left;
    font-weight: 900;
    color: #00A0B4;
    font-size: 16px;

}