@media only screen and (max-width: 1428px) {
    .form-inline.RestrictionBarActive  {
      display:flex!important;
      justify-content: flex-end;
      margin-right: 0!important;
      margin-bottom: .5em;
      width: 100%;
      float: none;
    }
  }

  @media only screen and (max-width: 1000px) {
    .form-inline.RestrictionBarActive  {
      display:flex!important;
      justify-content: center;
      margin-right: 0!important;
      margin-bottom: .5em;
      width: 100%;
      float: none;
    }
  }