.ButtonGroupItem{
    background-color: #39708C!important;
    border-color: #39708C!important;
}
.ButtonGroupItem:focus{
    outline: none!important;
    box-shadow: none!important;
}
.ButtonGroupItem:active{
    outline: none!important;
    box-shadow: none!important;
}
.ButtonGroupItem.active{
    outline: none!important;
    box-shadow: none!important;
}
.ButtonGroup .dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;
}