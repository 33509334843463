.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto';
  color: #384a5e;
}

.onePageDiv {
  padding: 35px 50px 35px 50px;
  width: 595pt;
  height: 842pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: relative;
  break-inside: avoid-page;
  break-after: always;
}

.allPages{
  height: 3360pt;
}

.gricevizLogo {
width: 210px;
}

.brainImageBox{
text-align: right;
position: absolute;
top: 0;
right: 0;
width: 350px;
height: 200px;
z-index: 0;
}

.brainImageText{
position: absolute;
top: 40px;
right: 50px;
font-size: 22pt;
font-weight: 900;
z-index: 2;
}

.headerDate {
  color: #7c7c7c;
  font-size: 11pt;
  font-weight: 400;
  position: absolute;
  top: 80px;
  right: 50px;
}

.brainImage{
z-index: 0;
width: 100%;
}



.headerBigText {
  font-size: 22pt;
  font-weight: 900;
  margin-bottom: 20px;
}




.avatar {
  margin-top: 10px;
  margin-bottom: 7px;
  width: 35mm;
  height: 35mm;
  border-radius: 1000px;
}

.name {
  font-size: 30pt;
  font-weight: 900;
}
.birthDate{
  width: 120px;
  color: white;
  background-color: #384a5e;
  font-weight: 500;
  font-size: 11pt;
  padding: 3px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 35px;

}

.birthDateIcon{
  font-size: 12pt!important;
  margin-right: 4px;
  margin-bottom: 3px;
  
}

.subHeader {
  font-size: 22pt;
  font-weight: 900;
 
}
.categoryBox {
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
}

.categoryIcon {
  width: 42px;
  height: 42px;
}

.categoryName {
  font-size: 22pt;
  font-weight: bold;
  margin-left: 7px;
}

.featureName {
  font-size: 10pt;
  font-weight: 500;
  margin-top: 8px;
  line-height: 12pt;
  display: flex;
  align-items: center;
}

.subFeature {
  margin-left: 7px;
  font-size: 10pt;
  line-height: 12pt;
  font-weight: 300;
}

.categoryIconAndFeaturesRow {
  flex: 1;
}

.scoreProgressDiv {
  width: 500px;
  height: 250px;
  margin-left: 4px;
  border-radius: 20px;
  background-color: #f5f8f9;
  padding: 20px;
}

.radarBox{
  width: 100%;
  height: 480px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #f5f8f9;
  border-radius: 20px;
  padding: 40px;
  margin-top: 24px;
}

.gameLevelsBox{
  width: 100%;
  height: 400px;

  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.playCountsByCategory{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageFooter{
position: absolute;
bottom: 0;
width: 100%;
padding: 0px 40px 30px 40px;
font-size: 10pt;
}

.footerLeftItemRow{
  
}

.footerLeftItem{
margin-right: 15px;
font-weight: 400;
color: #7c7c7c;
}

.footerPageNo{
width: 90px;
color: #231f20;
}

