.Corporation .ImageContainer {
    position:relative;
    padding-bottom:100%;
    overflow:hidden;
}

.Corporation .Image {
    width: 50%;
    height:50%;
    background-color:white;
    position:absolute;
    left: 25%;
    top:15%;
}

.Corporation .ImageContainer .Name {
    position: absolute;
    top: 68%;
    width: 100%;
    text-align: center;
}
.Corporation .DivisionSelect{
    display: flex;
    justify-content: space-between;
    cursor: pointer;    
    background-color: #F6F7F9;
    padding: 5px 10px;
    margin: 7px 0;
    border-radius: 3px;
}
.Corporation .DivisionSelect.Active {
    background-color: #7A7A7A;
    color: white;
    cursor: default;
    transition: background-color .2s;
    
}