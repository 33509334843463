.bubble {
	position: relative;
	background: #F0F0F0;
	border-radius: .4em;
  border-top-left-radius: 0;
}

.bubble:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-left: 0;
		margin-left: -12px;
    border-right: 15px solid #F0F0F0;
		border-bottom: 15px solid transparent;
  }

.dot:after {
    content: '';
    position: absolute;
		left: -34px;
    top: -6px;
    width: 15px;
    height: 15px;
		border-radius: 50%;
  }

.dot.register:after {
background-color: #23738C;
}

.dot.purchase:after {
	background-color: #00BC9D;
}

.dot.cancel:after {
	background-color: #FF6A7C;
}
