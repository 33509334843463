.PurchaseButton:hover span{
  color:white!important;
}

.PurchaseContainer li {
  color:#7B7B7B;
}
.PurchaseContainer{
  display: flex;
  justify-content: space-between;
  align-items: center!important;
}
.PurchaseButton:hover span{
  cursor: pointer;
  color:#7b7b7b!important;
}

@media only screen and (max-width: 1600px) {
  .ItemsAndCouponContainer{
    flex-direction: column!important;
  }
  .PurchaseContainer .Products {
    margin-top: 1em;
  }
}