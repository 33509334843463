.nav-card {
  color: black;
  text-decoration: none;
}

.nav-card:hover {
  /* color: #007bff; */
  text-decoration: none;
}

.card-container:hover {
  background: #d2d3d4;
}

.card-container {
  border-bottom: #007bff;
  border: #007bff;
  border-bottom-width: 2px;
}

.nav-card-active-class {
  color: #007bff;
}
