.Header{
    background-color:ghostwhite;
}
.Header-Container{
    height: 60px;
    padding: 0.5em;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.HeaderIconContainer{
    display: inline;
}
.HeaderRightIcon{
    margin-left: .5em;
}
.Header-Bottom {
    background: linear-gradient(to right, #00b4db, #0083b0); 
    height: 60px;
    padding: 0.5em 0;
}
.Header-Bottom-Icon-Container{
    position: relative;
    background-color: #fff;
    bottom:-50%;
}
.Header-Bottom-Icon-Item{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}
.NavBar-Brand {
    height: 100%;
}
.NavLink {
    color: #3f3f3f;
    padding: 1em;
    border-right: 1px solid #ccc;
}
.NavLink:hover {
    color: #0083b0;
    font-weight: bold;
    text-decoration: none;
}
.Header-Bottom-Icon-Item.Active {
    color: #0083b0;
    font-weight: bold;

}

.Header-Bottom-Icon-Item.Active::after{
    content: "^";
    transform: rotate(180deg) scaleX(2);
    position: absolute;
    bottom: 0;
    margin: 0;
}
.Navlink-Tag{
    font-size: .7em;
    margin-top: .5em;
}

.Language {
    cursor:pointer;
    color: #333;
    text-align: center;
    
}

.Language-Active {
    font-weight: bold;
}